<template>
  <div class="Awarp">
    <dashCard class="lvscBox10" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption"
      @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('lvscBox23_monitor','变压器监测', getZEdata)}}</template>
      <template slot="aside">
        <a-select v-model="searchObj['ITEM']" style="width: 220px" @change="handleChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="(item, key) in itemList" :key="key" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </template>
      <div class="card-content1">
        <div class="product-pre">
          <div>
            <img src="@/assets/imgs/echarts/bianyaqi.jpg" />
          </div>
        </div>
        <div class="product-info">
          <div class="product-content">
            <div class="product-status">
              <div :style="{
                background: this.newObj.fen1 == 0 ? '#51a34b' : 'red',
              }"></div>
             <div>{{commonLangHandler('lvscBox23_fan1','风机1', getZEdata)}}</div>
            </div>
            <div class="product-status">
              <div :style="{
                background: this.newObj.fen2 == 0 ? '#51a34b' : 'red',
              }"></div>
              <div>{{commonLangHandler('lvscBox23_fan2','风机2', getZEdata)}}</div>
            </div>
            <div class="product-status">
              <div :style="{
                background: this.newObj.doorOpen == 0 ? '#51a34b' : 'red',
              }"></div>
              <div>{{commonLangHandler('lvscBox23_netgate','网门', getZEdata)}}</div>
            </div>
            <div class="product-status">
              <div :style="{
                background: this.newObj.over == 0 ? '#51a34b' : 'red',
              }"></div>
             <div>{{commonLangHandler('lvscBox23_overTemp','超温', getZEdata)}}</div>
            </div>
            <div class="product-status">
              <div :style="{
                background: this.newObj.fault == 0 ? '#51a34b' : 'red',
              }"></div>
              <div>{{commonLangHandler('lvscBox23_failure','故障', getZEdata)}}</div>
            </div>
            <div class="product-status">
              <div :style="{
                background: this.newObj.trip == 0 ? '#51a34b' : 'red',
              }"></div>
             <div>{{commonLangHandler('lvscBox23_trip','跳闸', getZEdata)}}</div>
            </div>
          </div>
          <div class="product-info-item" style="margin-top:10px">
            <div class="product-info-item-top">
              <span>{{commonLangHandler('lvscBox23_phaseA','A相温度', getZEdata)}}</span>
              <span>{{ newObj.ATemperature.realValue }}</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{
                width:
                  `(${newObj.ATemperature.realValue}/${newObj.ATemperature.valuemax})` *
                  100 +
                  '%',
              }"></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{ newObj.ATemperature.valuemax }}</span>
            </div>
          </div>
          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>{{commonLangHandler('lvscBox23_phaseB','B相温度', getZEdata)}}</span>
              <span>{{ newObj.BTemperature.realValue }}</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{
                width:
                  `(${newObj.BTemperature.realValue}/${newObj.BTemperature.valuemax})` *
                  100 +
                  '%',
              }"></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{ newObj.BTemperature.valuemax }}</span>
            </div>
          </div>
          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>{{commonLangHandler('lvscBox23_phaseC','C相温度', getZEdata)}}</span>
              <span>{{ newObj.CTemperature.realValue }}</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{
                width:
                  `(${newObj.CTemperature.realValue}/${newObj.CTemperature.valuemax})` *
                  100 +
                  '%',
              }"></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{ newObj.CTemperature.valuemax }}</span>
            </div>
          </div>
        </div>
      </div>
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false"
      width="100%" :dialog-style="{ top: '0px' }">
      <div style="height: calc(100vh)">
        <lvscBox23 :option="option" :isModal="true" :orginSearchObj="searchObj" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import Img001 from "@/assets/imgs/echarts/bianyaqi.jpg";

export default {
  name: "lvscBox23",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      itemList: [],
      searchObj: {
        ITEM: "", //选中的项目
      },
      detailInfo: {},
      newObj: {
        fen1: 0,
        fen2: 0,
        over: 0,
        trip: 0,
        fault: 0,
        doorOpen: 0,
        ATemperature: {},
        BTemperature: {},
        CTemperature: {},
      },
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type: "变压器温控器",
        },
      }).then((res) => {
        //console.log(res);
        let arr = [];
        if (res.data.data != null) {
          res.data.data.forEach((item) => {
            let obj = {
              value: item.assetNumber,
              label: item.assetName,
            };
            arr.push(obj);
            //console.log(arr)
          });
        }
        this.itemList = arr;
        //console.log(this.itemList)
        if (this.itemList.length > 0) {
          this.searchObj.ITEM = this.itemList[0].value;
        }
        this.getData();
      });
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 切换项目
    handleChange(value) {
      this.getData();
    },
    // 获取数据
    getData() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/transformer/monitoring",
        method: "post",
        data: {
          assetNumber: this.searchObj.ITEM,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.newObj.fen1 = res.data.data.fan1.realValue;
          this.newObj.fan2 = res.data.data.fan2.realValue;
          this.newObj.over = res.data.data.over.realValue;
          this.newObj.trip = res.data.data.trip.realValue;
          this.newObj.fault = res.data.data.fault.realValue;
          this.newObj.doorOpen = res.data.data.doorOpen.realValue;
          this.newObj.ATemperature = res.data.data.ATemperature;
          this.newObj.BTemperature = res.data.data.BTemperature;
          this.newObj.CTemperature = res.data.data.CTemperature;
        }
      });
    },
    getData0() {
      this.isLoading = true;
      this.detailInfo = {
        value1: "ABB_3P 63A-400A",
        value2: "128",
        value3: "h",
        value4: "400",
        value5: "V",
        value6: "1000",
        value7: "A",
        value8: "2780",
        value9: "kWh",

        value10: "380V",
        value11: "200kW",
        value12: "80℃",
        productSrc: Img001,
      };
      this.isLoading = false;
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getList();
      // this.getData()
    }
  },
};
</script>

<style lang="less" scoped>
.lvscBox10 {
  .card-content1 {
    display: flex;
    justify-content: space-around;

    .product-pre {
      width: 260px;
      height: 270px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .product-info {
      // flex: 1;
      width: 57%;

      .product-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        .product-status {
          width: 30%;
          height: 32px;
          line-height: 45px;
          display: flex;

          div {
            &:nth-child(1) {
              width: 10px;
              height: 10px;
              background: #51a34b;
              border-radius: 3px;
              margin-top: 7px;
            }

            &:nth-child(2) {
              font-size: 18px;
              font-weight: 500;
              color: #595959;
              line-height: 24px;
              margin-left: 5px;
            }
          }
        }

        // .product-status {
        //   div {
        //     &:nth-child(1) {
        //       font-size: 16px;
        //       font-weight: 400;
        //       color: #595959;
        //       line-height: 24px;
        //     }

        //     &:nth-child(2) {
        //       margin-top: 6px;
        //       margin-bottom: 16px;

        //       font-size: 36px;
        //       font-weight: 500;
        //       color: #3366ff;
        //       line-height: 48px;
        //     }
        //   }
        // }
      }

      .product-info-item {
        margin-bottom: 8px;

        .product-info-item-top {
          display: flex;
          justify-content: space-between;

          font-size: 16px;
          font-weight: 500;
          color: #1f1f1f;
          line-height: 20px;
        }

        .product-info-item-progress {
          width: 100%;
          height: 8px;
          background: #dbdbdb;
          margin-top: 7px;
          margin-bottom: 8px;

          span {
            display: block;
            height: inherit;
            width: 0%;
            background: #51a34b;
          }
        }

        .product-info-item-bottom {
          display: flex;
          justify-content: space-between;

          font-size: 14px;
          font-weight: 400;
          color: #696969;
          line-height: 16px;
        }
      }
    }
  }

  /deep/ .ant-descriptions-bordered {
    .ant-descriptions-item-label {
      background: transparent;
      width: 362px;
    }

    .ant-descriptions-item-content {
      background: #f5f5f5;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
